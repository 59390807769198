// TODO: All of the icons should be imported from the refera lib
import TimerPauseIcon from '_assets/icons/ic-timer-pause.svg'
import NewOrdersIcon from '_assets/icons/ic-new-orders.svg'
import BlueFileIcon from '_assets/svgs/blue-file.svg'
import OpenBudgetIcon from '_assets/icons/ic-open-budget.svg'
import CalendarBudgetIcon from '_assets/svgs/calendar-tick.svg'
import FlagBudgetIcon from '_assets/svgs/flag.svg'
import ArrowBudgetIcon from '_assets/svgs/arrow-circle.svg'
import ExclamationBudgetIcon from '_assets/svgs/exclamation.svg'
import BudgetScheduleIcon from '_assets/icons/ic-budget-schedule.svg'
import FlashBudgetIcon from '_assets/icons/ic-flash-budget.svg'
import BrushOrderIcon from '_assets/icons/ic-brush-order.svg'
import BoxOrderIcon from '_assets/icons/ic-box-order.svg'
import ArchiveIcon from '_assets/svgs/archive-widget.svg'
import AttentionIcon from '_assets/images/AttentionIcon.gif'

export const MODAL_TYPES = {
  OPEN_BUDGETS: 'OPEN_BUDGETS',
  DELAYED_OPEN_BUDGETS: 'DELAYED_OPEN_BUDGETS',
  EXECUTION: 'EXECUTION',
  DELAYED_EXECUTION: 'DELAYED_EXECUTION',
}

export const modal = {
  OPEN_BUDGETS: [
    {
      title: 'Agendar visita orçamento',
      icon: BudgetScheduleIcon,
      attributes: ['budget_schedule'],
      dataAttributes: {
        'data-step-status': 'budget_schedule',
      },
    },
    {
      title: 'Enviar orçamento',
      icon: OpenBudgetIcon,
      attributes: ['send_budget'],
      dataAttributes: {
        'data-step-status': 'send_budget',
      },
    },
  ],
  DELAYED_OPEN_BUDGETS: [
    {
      title: 'Agendar visita orçamento em atraso',
      icon: BudgetScheduleIcon,
      attributes: ['budget_schedule'],
      dataAttributes: {
        'data-step-status': 'budget_schedule',
        'data-situation': 'delayed',
      },
      isDelayed: true,
    },
    {
      title: 'Enviar orçamento em atraso',
      icon: OpenBudgetIcon,
      attributes: ['send_budget'],
      dataAttributes: {
        'data-step-status': 'send_budget',
        'data-situation': 'delayed',
      },
      isDelayed: true,
    },
  ],
  EXECUTION: [
    {
      title: 'Executar serviço',
      icon: BrushOrderIcon,
      attributes: ['send_for_execution'],
      dataAttributes: {
        'data-step-status': 'send_for_execution',
      },
    },
  ],
  DELAYED_EXECUTION: [
    {
      title: 'Executar serviço em atraso',
      icon: BrushOrderIcon,
      attributes: ['send_for_execution'],
      dataAttributes: {
        'data-step-status': 'send_for_execution',
        'data-situation': 'delayed',
      },
    },
    {
      title: 'Chamados para finalizar em atraso',
      icon: BoxOrderIcon,
      attributes: ['finish_service_order'],
      dataAttributes: {
        'data-step-status': 'finish_service_order',
        'data-situation': 'delayed',
      },
    },
  ],
}

export const MainWidgets = ({
  isPausedCount,
  notViewedHelpRequestCount,
  urgentPriorityCount,
  handleGoToHelpRequestView,
}) => [
  ...(urgentPriorityCount > 0
    ? [
        {
          title: 'Chamados URGENTES',
          icon: AttentionIcon,
          attributes: ['urgent_priority'],
          dataAttributes: {
            'data-urgent': 'urgent',
          },
          quantityDefault: urgentPriorityCount,
        },
      ]
    : []),
  {
    title: 'Orçamentos pré-aprovados',
    icon: BlueFileIcon,
    attributes: ['budget_pre_approved'],
    dataAttributes: {
      'data-step-status': 'budget_pre_approved',
    },
    showDelayed: true,
  },
  ...(isPausedCount
    ? [
        {
          title: 'Chamados pausados',
          icon: TimerPauseIcon,
          attributes: ['is_paused'],
          dataAttributes: {
            'data-situation': 'paused',
          },
          quantityDefault: isPausedCount,
          ...(notViewedHelpRequestCount && {
            secondaryAction: {
              dataAttributes: {
                'data-situation': 'paused',
              },
              description: `${notViewedHelpRequestCount} ${
                notViewedHelpRequestCount === 1 ? 'resposta não lida' : 'respostas não lidas'
              }`,
              onClick: handleGoToHelpRequestView,
            },
          }),
        },
      ]
    : []),
]

export const SecondaryWidgets = [
  {
    title: 'Novos chamados',
    icon: NewOrdersIcon,
    attributes: ['new_service_orders'],
    dataAttributes: {
      'data-step-status': 'new_service_orders',
    },
    showDelayed: true,
  },
  {
    title: 'Orçamentos em aberto',
    icon: OpenBudgetIcon,
    attributes: ['budget_schedule', 'send_budget'],
    dataAttributes: {
      'data-modal-type': MODAL_TYPES.OPEN_BUDGETS,
    },
    showDelayed: true,
  },
  {
    title: 'Orçamentos em análise',
    icon: ArrowBudgetIcon,
    attributes: ['waiting_budget_approval'],
    dataAttributes: {
      'data-step-status': 'waiting_budget_approval',
    },
  },
  {
    title: 'Chamados para agendar',
    icon: CalendarBudgetIcon,
    attributes: ['service_schedule'],
    dataAttributes: {
      'data-step-status': 'service_schedule',
    },
    showDelayed: true,
  },
  {
    title: 'Chamados para executar',
    icon: FlashBudgetIcon,
    attributes: ['send_for_execution'],
    dataAttributes: {
      'data-step-status': 'send_for_execution',
    },
    // NOTE: Business rule[PREF-4859]
    // It is impossible for a service order in which step_status is waiting_for_service_execution
    // to be delayed, so, in this case, the delay-related info is only about the service_finished.
    showDelayed: true,
  },
  {
    title: 'Chamados para finalizar',
    icon: FlagBudgetIcon,
    attributes: ['finish_service_order'],
    dataAttributes: {
      'data-step-status': 'finish_service_order',
    },
    showDelayed: true,
  },
  {
    title: 'Finalizações reprovadas',
    icon: ExclamationBudgetIcon,
    attributes: ['problems_finishing_service'],
    dataAttributes: {
      'data-step-status': 'problems_finishing_service',
    },
    showDelayed: true,
  },
  {
    title: 'Chamados encerrados',
    icon: ArchiveIcon,
    attributes: ['finished_services'],
    dataAttributes: {
      'data-step-status': 'finished_services',
    },
    showDelayed: true,
  },
]
